/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


import React from 'react';
import PageLayout from './src/layouts/layout';

import './src/styles/_reboot.scss';
import './src/styles/_global.scss';


export const wrapPageElement = ({ element, props }) => {
  return <PageLayout location={props.data?.wpPage?.slug ? props.data?.wpPage?.slug : props.data?.wpProject?.slug}>{element}</PageLayout>
};


//require('./src/layouts/layout.scss')