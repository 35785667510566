import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';

const NavItem = ({ item, index, handleOnMouseOver }) => {
  const subMenuRef = useRef(null);
  const [isToggled, setIsToggled] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() =>{
    if(isToggled) {
      setContentHeight(subMenuRef.current.offsetHeight);
    } else {
      setContentHeight(0);
    }

    setContentHeight(isToggled ? subMenuRef.current.offsetHeight : 0);
  },[subMenuRef, isToggled])

  return(
    <li className="nav__list-item" key={index} onMouseOver={handleOnMouseOver} data-uid={item.id}>
        <Link to={item.uri} className="nav__link h3 serif">{item.label}</Link>
        
        {item.childItems.nodes.length ? (
          <>
            <div className="nav__list-arrow" onClick={() => setIsToggled(!isToggled)}></div>
            <div className="nav__childlist-wrap" style={{'maxHeight': contentHeight}}>
              <ul ref={subMenuRef} className="nav__childlist-mobile" data-uid={item.id}>
                {item.childItems.nodes.map((childItem, index) => (
                  <li key={index} className="nav__childitem-item">
                    <Link to={childItem.uri} className="nav__link h4 serif">{childItem.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : ''}
    </li>
  )
}

export default NavItem;