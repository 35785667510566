import React from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';

let timeout = 1000;

let init = true;

const PageTransition = ({ children, location }) => (
  <TransitionGroup className="transition-group">
    <ReactTransition
      unmountOnExit={true}
      key={location}
      timeout={timeout}
      // onExit={node => {
      //   const parallaxElements = node.querySelectorAll('.parallax-element');

      //   parallaxElements.forEach(element => {
      //     const transform = element.style.transform;
      //     const num = parseFloat(transform.split(' ')[0].match(/-?\d+\.\d+/));
      //     const roundedNum = Math.round(num * 1000) / 1000; // round to one decimal place
      //     const inverseNum = roundedNum * -1;

      //     element.style.left = `${inverseNum}%`;
      //   });
      // }}
      onEnter={node => {
        setTimeout(() => {
          let horizontalScroll = false;
          const children = node.querySelector('[asscroll-container]').children;
          const filteredChildren = Array.from(children).filter(child => child.hasAttribute('asscroll'));

          if (node.querySelector('main') && node.querySelector('main').classList.contains('horizontal-scroll')) {
            horizontalScroll = true;
            document.querySelector('.asscrollbar')?.classList.add('hidden');
          }

          if(!horizontalScroll) {
            document.querySelector('.asscrollbar')?.classList.remove('hidden');
          }

          if(window.innerWidth >= 1024) {
            // if(init) {
              window.scroll.enable({ newScrollElements: filteredChildren, horizontalScroll, reset: true });
              // init = false;

            // } else {
              // window.scroll.enable({ newScrollElements: filteredChildren, reset: true, horizontalScroll });
            // }
            window.scroll.update();
            ScrollTrigger.refresh();
            // update all scroll trigger timelines
          }

        }, timeout * 2);
      }}
      >
      {(status) => (
        <div className={`woosh ${status}`}>
          {children}
        </div>
      )}
    </ReactTransition>
  </TransitionGroup>
);

export default PageTransition;