/**
 *
 * This is the website layout / wrapper
 * Look in gatsby-browser.js for how this wraps the website
 *
 */

import React from 'react';
import Header from '../components/Header/Header';
import PageTransition from '../components/PageTransition';
import { Script } from 'gatsby';
import { Store } from '../utils/store';

const PageLayout = ({ location, children }) => {
  return (
    <Store>
      <PageTransition location={location}>
        <div
          className={`layout-wrapper slug-${
            location === "/" ? "home" : location
          }`}
        >
          <Header />
          {children}
        </div>
      </PageTransition>
      <Script id="hs-script-loader" src="//js-na1.hs-scripts.com/43804974.js" />
    </Store>
  )
}

export default PageLayout
