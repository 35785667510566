import React, { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import ValidationMessages from "../ValidationMessages"

const ReCaptchaField = ({
  field,
  validationMessages = {},
  handleChange,
  formId,
  reCaptchaElement,
}) => {
  return (
    <div
      className={
        field.layoutGridColumnSpan === 6 ? "col col-50-desktop" : "col col-100"
      }
    >
      <ReCAPTCHA
        ref={reCaptchaElement}
        sitekey="6LduH5knAAAAADVZrkHZ5h74Kl7cqkhkIj52pNZY"
      />
      <ValidationMessages
        fieldId={field.id}
        validationMessages={validationMessages}
        formId={formId}
      />
    </div>
  )
}

export default ReCaptchaField
