import React, { useState } from 'react'

const ValidationMessages = ({ fieldId, validationMessages, formId = false }) => {
  if(validationMessages[fieldId] === "") {
    validationMessages[fieldId] = 'Please match the phone number format (###) ###-####'
  }

  if(!validationMessages[fieldId]) {
    return null;
  }

  return (
    <div className="validation-messages col-100">
      {validationMessages[fieldId] ? validationMessages[fieldId] : null}
    </div>
   );
}

export default ValidationMessages;