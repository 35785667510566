import React, { useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import gsap from 'gsap';
import LinkableIcon from '../LinkableIcon/LinkableIcon';
import NavItem from '../NavItem';
// import LinkedInIcon from '../../images/linkedin.svg';
// import InstagramIcon from '../../images/instagram.svg';
// import VimeoIcon from '../../images/vimeo.svg';

import './Nav.scss';

const Nav = ({ isActive, handleClick, toggleForm }) => {
  const [selectedUid, setSelectedUid] = useState(null);
  const year = new Date().getFullYear();
  const { wpMenu: { menuItems: { nodes } }, wp: { acfOptionsThemeOptions: { themeSettings: { vimeoUrl, linkedinUrl, instagramUrl }}} } = useStaticQuery(graphql`
    {
      wpMenu(locations: {eq: GATSBY_HEADER_MENU}) {
        menuItems {
          nodes {
            id
            uri
            label
            parentId
            childItems {
              nodes {
                uri
                label
                parentId
              }
            }
          }
        }
      }

      wp {
        acfOptionsThemeOptions {
          themeSettings {
            vimeoUrl
            linkedinUrl
            instagramUrl
          }
        }
      }
    }
  `);

  const parentMenuItems = nodes.filter(item => !item.parentId);
  const childMenuItems = nodes.reduce((acc, item, index) => {
    if (item.childItems.nodes.length > 0) {
      acc.push({ ...item, index });
    }
    return acc;
  }, []);



  useEffect(() => {
    const navListTl = gsap.timeline({
      paused: true,
    });

    navListTl.fromTo('.nav__list .nav__list-item', {
      x: '-10%',
      duration: 0.85,
      opacity: 0,
      stagger: 0.1,
    },{
      x: '0%',
      opacity: 1,
      duration: 0.5,
      ease: 'power2.InOut',
      delay: 0.8,
      stagger: 0.1
    });

    if(isActive) {
      navListTl.play();
    }

    return () => {
      navListTl.kill();
    }

  },[isActive]);

  const handleNavClick = (e) => {
    const { target } = e;

    if(target.classList.contains('nav')) {
      handleClick();
    }
  }


  const handleOnMouseOver = (e) => {
    if(window.innerWidth >= 1024) {
      const liTarget = e.target.tagName === 'A' ? e.target.parentNode : e.target;
      const uid = liTarget.dataset.uid;
      setSelectedUid(uid);
    }
  }

  return (
    <>
    <nav className={`nav${isActive ? ' active' : ''}`}  onClick={(e) => handleNavClick(e)}>
      <div className="nav__wrapper">
        <div className="nav__block">
          <ul className="nav__list">
            {parentMenuItems ? parentMenuItems.map((item, index) => (
              <NavItem key={index} item={item} handleOnMouseOver={handleOnMouseOver} />
            )) : ''}
              <li className="nav__list-item">
                <button className="nav__button h3 sans-serif" onClick={() => {toggleForm(); handleClick();}}>Start a project</button>
              </li>
          </ul>

            {childMenuItems ? childMenuItems.map((parentItem, index) => (
              <ul key={index} className={`nav__childlist${selectedUid === parentItem.id ? ' show' : ''}`} data-uid={parentItem.id} style={{ 'paddingTop': parentItem.index * 60}}>
                {parentItem.childItems.nodes.length ? parentItem.childItems.nodes.map((item, index) => (
                  <li key={index} className="nav__childitem-item">
                    <Link to={item.uri} className="nav__link h4 serif">{item.label}</Link>
                  </li>
                )) : '' }
              </ul>
            )) : ''}
        </div>

        <div className="nav__links">
          <div className="nav__links-socials">
            {instagramUrl ? <LinkableIcon url={instagramUrl} target="_blank" icon={<img src="../../images/instagram.svg"/>} /> : ''}
            {linkedinUrl ? <LinkableIcon url={linkedinUrl} target="_blank" icon={<img src="../../images/linkedin.svg"/>} /> : ''}
            {vimeoUrl ? <LinkableIcon url={vimeoUrl} target="_blank" icon={<img src="../../images/vimeo.svg"/>} /> : ''}
          </div>

          <div className="nav__links-info">
            <Link to="/privacy-policy">Privacy Policy</Link>
            {/* <Link to="/disclaimer">Disclaimer</Link> */}
            <p>&copy; Blank Canvas {year}</p>
          </div>
        </div>

      </div>
    </nav>

    <div className={`nav-bg ${isActive ? ' active' : ''}`}></div>
    </>
   );
}

export default Nav;