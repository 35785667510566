import React from "react"
import ValidationMessages from "../ValidationMessages"

const PhoneField = ({
  field,
  validationMessages = {},
  handleChange,
  formId,
}) => {
  return (
    <>
      <div
        className={
          field.layoutGridColumnSpan === 6
            ? "col col-50-desktop"
            : "col col-100"
        }
      >
        {field.labelPlacement !== "HIDDEN" && (
          <label htmlFor={`input_${field.id}`}>{field.label}</label>
        )}
        <input
          className="text-input"
          type="tel"
          name={`input_${field.id}`}
          placeholder={field.placeholder}
          onChange={handleChange}
          required={field.isRequired}
        />
        <ValidationMessages
          fieldId={field.id}
          validationMessages={validationMessages}
          formId={formId}
        />
      </div>
    </>
  )
}

export default PhoneField

