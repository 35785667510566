import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AddressField from "./fields/AddressField"
import CheckboxField from "./fields/CheckboxField"
import DateField from "./fields/DateField"
import EmailField from "./fields/EmailField"
import NameField from "./fields/NameField"
import PhoneField from "./fields/PhoneField"
import RadioField from "./fields/RadioField"
import SelectField from "./fields/SelectField"
import TextField from "./fields/TextField"
import TextAreaField from "./fields/TextAreaField"
import HTMLField from "./fields/HTMLField"
import ReCaptchaField from "./fields/ReCaptchaField"

const ChriateFormFields = ({
  formId,
  handleChange,
  validationMessages,
  formState,
  setFormState,
  isHome,
  reCaptchaElement,
}) => {
  const data = useStaticQuery(graphql`
    query SupportFormsQuery {
      allWpGfForm {
        nodes {
          databaseId
          formFields {
            nodes {
              ... on WpTextField {
                isRequired
                layoutGridColumnSpan
                adminLabel
                autocompleteAttribute
                label
                type
                inputMaskValue
                id
                placeholder
                value
                labelPlacement
                size
                conditionalLogic {
                  logicType
                  actionType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpSelectField {
                isRequired
                layoutGridColumnSpan
                adminLabel
                autocompleteAttribute
                label
                labelPlacement
                type
                id
                placeholder
                value
                size
                choices {
                  value
                  text
                }
                conditionalLogic {
                  logicType
                  actionType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpHtmlField {
                id
                content
                layoutGridColumnSpan
                type
              }
              ... on WpCheckboxField {
                isRequired
                layoutGridColumnSpan
                adminLabel
                label
                labelPlacement
                type
                id
                value
                inputs {
                  id
                  label
                }
                conditionalLogic {
                  logicType
                  actionType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpEmailField {
                isRequired
                layoutGridColumnSpan
                adminLabel
                label
                labelPlacement
                type
                id
                placeholder
                value
                size
                conditionalLogic {
                  logicType
                  actionType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpPhoneField {
                isRequired
                id
                layoutGridColumnSpan
                autocompleteAttribute
                label
                labelPlacement
                value
                type
                size
                placeholder
                defaultValue
                conditionalLogic {
                  actionType
                  logicType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpTextAreaField {
                isRequired
                id
                layoutGridColumnSpan
                adminLabel
                label
                labelPlacement
                type
                value
                placeholder
                maxLength
                defaultValue
                conditionalLogic {
                  actionType
                  logicType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpRadioField {
                isRequired
                id
                layoutGridColumnSpan
                choices {
                  value
                  text
                }
                cssClass
                label
                labelPlacement
                type
                value
                conditionalLogic {
                  actionType
                  logicType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
              }
              ... on WpCaptchaField {
                id
                type
                layoutGridColumnSpan
              }
              ... on WpNameField {
                isRequired
                id
                layoutGridColumnSpan
                adminLabel
                canPrepopulate
                label
                labelPlacement
                inputs {
                  id
                  label
                }
                nameValues {
                  first
                  last
                  middle
                  prefix
                  suffix
                }
                conditionalLogic {
                  actionType
                  logicType
                  rules {
                    fieldId
                    operator
                    value
                  }
                }
                type
              }
            }
          }
        }
      }
    }
  `)

  const form =
    data.allWpGfForm.nodes.find(form => form.databaseId === formId) || null

  const checkConditionalLogic = (field, formState, formFields) => {
    if (field.conditionalLogic && field.conditionalLogic.rules.length > 0) {
      let matches = 0

      field.conditionalLogic.rules.forEach(rule => {
        if (formState["input_" + rule.fieldId] === rule.value) {
          matches++
        }
      })

      switch (field.conditionalLogic.actionType) {
        case "SHOW":
          if (matches !== field.conditionalLogic.rules.length) {
            return false
          }
          break

        case "HIDE":
          if (matches === field.conditionalLogic.rules.length) {
            return false
          }
          break

        default:
          break
      }
    }

    if (
      field.conditionalLogic &&
      field.conditionalLogic.actionType === "SHOW"
    ) {
      // ? Hmm
      const dependencies = field.conditionalLogic.rules.map(rule => {
        return formFields.find(f => f.id === rule.fieldId)
      })

      for (let i = 0; i < dependencies.length; i++) {
        const dependency = dependencies[i]

        if (!checkConditionalLogic(dependency, formState, formFields)) {
          return false
        }
      }
    }

    return true
  }

  if (!form) {
    return <></>
  }

  return (
    <>
      {form.formFields.nodes.map(field => {
        if (!checkConditionalLogic(field, formState, form.formFields.nodes)) {
          return null
        }

        switch (field.type) {
          case "ADDRESS":
            return (
              <AddressField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "CHECKBOX":
            return (
              <CheckboxField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                setFormState={setFormState}
                formId={formId}
                isHome={isHome}
              />
            )
          case "DATE":
            return (
              <DateField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "EMAIL":
            return (
              <EmailField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "NAME":
            return (
              <NameField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "PHONE":
            return (
              <PhoneField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "RADIO":
            return (
              <RadioField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "SELECT":
            return (
              <SelectField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "TEXT":
            return (
              <TextField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "TEXTAREA":
            return (
              <TextAreaField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "HTML":
            return (
              <HTMLField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                handleChange={handleChange}
                formState={formState}
                formId={formId}
              />
            )
          case "CAPTCHA":
            return (
              <ReCaptchaField
                key={field.id}
                field={field}
                validationMessages={validationMessages}
                reCaptchaElement={reCaptchaElement}
                formId={formId}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default ChriateFormFields
