import React from "react"
import ValidationMessages from "../ValidationMessages"

const CheckboxField = ({
  field,
  validationMessages = {},
  handleChange,
  formId,
  formState,
  setFormState,
  isHome,
}) => {
  const handleCheckbox = e => {
    const { name, value, checked } = e.target

    if (checked) {
      setFormState({
        ...formState,
        [name]: value,
      })
    } else {
      const { [name]: value, ...rest } = formState
      setFormState(rest)
    }
  }

  return !isHome ? (
    <div
      className={
        field.layoutGridColumnSpan === 6
          ? "col col-50-desktop option-picker"
          : "col col-100 option-picker"
      }
    >
      {field.labelPlacement !== "HIDDEN" && (
        <label htmlFor={`input_${field.id}`}>{field.label}</label>
      )}
      <ul className="checkbox">
        {field.inputs.map((input, i) => {
          const index = i + 1
          return (
            <li key={input.id}>
              <input
                id={`input_${formId}_${field.id}_${index}`}
                name={`input_${field.id}.${index}`}
                type="checkbox"
                value={input.label}
                onChange={handleCheckbox}
              />
              <label htmlFor={`input_${formId}_${field.id}_${index}`}>
                {input.label}
              </label>
            </li>
          )
        })}
      </ul>
      <ValidationMessages
        fieldId={field.id}
        validationMessages={validationMessages}
        formId={formId}
      />
    </div>
  ) : (
    <div
      className={
        field.layoutGridColumnSpan === 6
          ? "col col-50-desktop option-picker"
          : "col col-100 option-picker"
      }
    >
      <details>
        {field.labelPlacement !== "HIDDEN" && (
          <summary>
            <label htmlFor={`input_${field.id}`}>{field.label}</label>
          </summary>
        )}
        <ul className="checkbox">
          {field.inputs.map((input, i) => {
            const index = i + 1
            return (
              <li key={input.id}>
                <input
                  id={`input_${field.id}.${index}`}
                  name={`input_${field.id}.${index}`}
                  type="checkbox"
                  value={input.label}
                  onChange={handleCheckbox}
                />
                <label htmlFor={`input_${field.id}.${index}`}>
                  {input.label}
                </label>
              </li>
            )
          })}
        </ul>
      </details>
      <ValidationMessages
        fieldId={field.id}
        validationMessages={validationMessages}
        formId={formId}
      />
    </div>
  )
}

export default CheckboxField
