import React from "react"
import ValidationMessages from "../ValidationMessages"

const RadioField = ({
  field,
  validationMessages = {},
  handleChange,
  formState,
  formId,
}) => {
  return (
    <div
      className={
        field.layoutGridColumnSpan === 6
          ? `col col-50-desktop option-picker${
              field.cssClass ? " " + field.cssClass : ""
            }`
          : `col col-100 option-picker${
              field.cssClass ? " " + field.cssClass : ""
            }`
      }
    >
      {field.labelPlacement !== "HIDDEN" && (
        <label htmlFor={`input_${field.id}`}>{field.label}</label>
      )}
      <ul className="radio">
        {field.choices.map((input, index) => {
          return (
            <li key={field.id + "-" + index}>
              <input
                id={`input_${field.id}_${index}`}
                name={`input_${field.id}_${index}`}
                type="radio"
                value={input.value}
                onChange={handleChange}
                checked={
                  formState[`input_${field.id}`] === input.value ? true : false
                }
                required={index === 0 ? true : false}
              />
              <label
                htmlFor={`input_${field.id}_${index}`}
                dangerouslySetInnerHTML={{ __html: input.text }}
              />
            </li>
          )
        })}
      </ul>
      <ValidationMessages
        fieldId={field.id}
        validationMessages={validationMessages}
        formId={formId}
      />
    </div>
  )
}

export default RadioField
