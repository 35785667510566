exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-canvas-page-js": () => import("./../../../src/templates/canvas-page.js" /* webpackChunkName: "component---src-templates-canvas-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-horizontal-page-js": () => import("./../../../src/templates/horizontal-page.js" /* webpackChunkName: "component---src-templates-horizontal-page-js" */),
  "component---src-templates-horizontal-project-js": () => import("./../../../src/templates/horizontal-project.js" /* webpackChunkName: "component---src-templates-horizontal-project-js" */),
  "component---src-templates-journal-post-js": () => import("./../../../src/templates/journal-post.js" /* webpackChunkName: "component---src-templates-journal-post-js" */),
  "component---src-templates-vertical-page-js": () => import("./../../../src/templates/vertical-page.js" /* webpackChunkName: "component---src-templates-vertical-page-js" */)
}

