import React from "react"
import ValidationMessages from "../ValidationMessages"

const HTMLField = ({
  field,
  validationMessages = {},
  handleChange,
  formId,
}) => {
  return field.content ? (
    <div
      className={
        field.layoutGridColumnSpan === 6 ? "col col-50-desktop" : "col col-100"
      }
    >
      <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: field.content }}
      />
    </div>
  ) : (
    ""
  )
}

export default HTMLField
