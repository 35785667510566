
import React, { createContext, useReducer } from 'react'

const initialState = {
  scrollPos: 0,
  scrollType: 'vertical',
  slug: '/',
}

export const Context = createContext(initialState)

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'update':
        return { ...state, scrollPos: action.payload }
      case 'scrollType':
        return { ...state, scrollType: action.payload }
      case 'updateSlug':
        return { ...state, slug: action.payload }
      default:
        return state
    }
  }, initialState);


  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}