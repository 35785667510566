import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Nav from "../Nav/Nav"
import Popup from "../Popup/Popup"
import BlankCanvasLgLogo from "../../images/blank-canvas-lg-logo.svg"
import BlankCanvasSmLogo from "../../images/blank-canvas-sm-logo.svg"
import { Context } from "../../utils/store"

import "./Header.scss"

const Header = () => {
  const [state] = useContext(Context)
  const [isActive, setIsActive] = useState(false)
  const [isFormActive, setIsFormActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const handleClick = (
    turnOff = false,
    state = isActive,
    setState = setIsActive
  ) => {
    if (!turnOff) {
      setState(!state)
    } else {
      setState(false)
    }
  }

  const handleScroll = scrollPos => {
    if (scrollPos > 15) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  function handleLinkClick(e) {
    e.preventDefault()
    handleClick(!isActive)

    if (window.innerWidth < 1024) {
      let href = this.getAttribute("href").split("#")[1]
      let target = document.querySelector("#" + href)

      if (target) {
        target.scrollIntoView({
          behavior: "smooth",
        })
      } else {
        window.location.href = `/#${href}`
      }
    }
  }

  // Is the site lagging? the useContext is probably at fault.
  useEffect(() => {
    if (window.innerWidth >= 1024) {
      handleScroll(state.scrollPos)
    } else {
      window.addEventListener("scroll", () => handleScroll(window.scrollY))

      return () => {
        window.removeEventListener("scroll", () => handleScroll(window.scrollY))
      }
    }
  }, [state])

  useEffect(() => {
    document.querySelectorAll('a[href*="#contact"]').forEach(anchor => {
      anchor.addEventListener("click", handleLinkClick)
    })

    return () => {
      document.querySelectorAll('a[href*="#contact"]').forEach(anchor => {
        anchor.removeEventListener("click", handleLinkClick)
      })
    }
  }, [])

  useEffect(() => {
    document.querySelectorAll('a[href*="#contact"]').forEach(anchor => {
      anchor.addEventListener("click", handleLinkClick)
    })

    return () => {
      document.querySelectorAll('a[href*="#contact"]').forEach(anchor => {
        anchor.removeEventListener("click", handleLinkClick)
      })
    }
  }, [])

  return (
    <>
      <header
        className={`header${isActive ? " active" : ""}${
          scrolled ? " scrolled" : ""
        }`}
      >
        <div className="header__wrapper">
          <div className="header__motif">
            <Link to="/">
              {/* <BlankCanvasSmLogo /> */}
              {/* <img src="../../images/blank-canvas-sm-logo.svg" /> */}
              <BlankCanvasSmLogo />
            </Link>
          </div>

          <div className="header__logo">
            <Link to="/">
              {/* <img src="../../images/blank-canvas-lg-logo.svg" /> */}
              <BlankCanvasLgLogo />
            </Link>
          </div>

          <div
            className={`header__burger${isActive ? " active" : ""}`}
            onClick={() => handleClick(false)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>

      <Nav
        isActive={isActive}
        handleClick={() => handleClick(false)}
        toggleForm={() => handleClick(false, isFormActive, setIsFormActive)}
      />

      <button
        className="btn fixed-btn start-a-project-btn"
        onClick={() => handleClick(false, isFormActive, setIsFormActive)}
      >
        Start a project
      </button>

      <Popup
        isActive={isFormActive}
        handleClick={() => handleClick(true, isFormActive, setIsFormActive)}
      />
    </>
  )
}

export default Header
